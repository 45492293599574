import "./style.scss";

import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { weekBoundsFromDate } from "@src/lib/utils";
import { arrowBack, arrowForward } from "ionicons/icons";
import moment from "moment-timezone";
import { FC } from "react";

import { ShiftListHeaderProps } from "./models";

const ShiftListHeader: FC<ShiftListHeaderProps> = ({
  shiftMessage,
  handleBackClick,
  handleForwardClick,
  relativeWeek,
}) => {
  const getWeekRange = (relativeWeek: number) => {
    const { start, end } = weekBoundsFromDate(
      moment().add(relativeWeek, "weeks"),
      "America/Los_Angeles"
    );
    const after = start.format("MMM D");
    const before = end.format("MMM D, YYYY");
    return `${after} - ${before}`;
  };

  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center ion-align-items-center">
        <IonCol size="1" style={{ padding: 0, marginTop: -3 }}>
          <IonButton
            className="payroll-arrow-icons"
            color="primary"
            shape="round"
            fill="clear"
            size="large"
            onClick={handleBackClick}
          >
            <IonIcon slot="icon-only" icon={arrowBack} mode="ios" />
          </IonButton>
        </IonCol>
        <IonCol
          size="10"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            display: "flex",
            marginBottom: "7px",
          }}
        >
          <h5 style={{ marginBottom: 0 }}>{getWeekRange(relativeWeek)}</h5>
        </IonCol>
        <IonCol size="1" style={{ padding: 0, marginLeft: -10, marginTop: -6.5 }}>
          <IonButton
            className="payroll-arrow-icons"
            disabled={relativeWeek === 0}
            color="primary"
            shape="round"
            fill="clear"
            size="large"
            onClick={handleForwardClick}
          >
            <IonIcon slot="icon-only" icon={arrowForward} mode="ios" />
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="1"></IonCol>
        <IonCol size="10">
          <strong>{shiftMessage}</strong>
        </IonCol>
        <IonCol size="1"></IonCol>
      </IonRow>
    </IonGrid>
  );
};

export { ShiftListHeader };
