import { IonList } from "@ionic/react";
import { isEmpty } from "lodash";
import { FC } from "react";

import { ShiftListProps } from "./models";
import { ShiftListItem } from "./shiftListItem";

const ShiftList: FC<ShiftListProps> = ({ shifts }) => {
  return (
    <IonList>
      {!isEmpty(shifts) && shifts.map((shift) => <ShiftListItem key={shift._id} shift={shift} />)}
    </IonList>
  );
};

export { ShiftList };
