import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { PayrollList, PayrollSummary } from "@src/lib/interface";
import request from "superagent";

import { getAuthHeader } from "../superagent";

const fetchPayrollData = async (queryData: object): Promise<PayrollList[]> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/shift/payroll/mobile`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .query(queryData)
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

const fetchLifeTimeEarnings = async (): Promise<PayrollSummary> => {
  return await request
    .get(`${environmentConfig.REACT_APP_BASE_API_URL}/shift/payroll/summary`)
    .retry(1, (err) => {
      if (!err) {
        return false;
      }
      return true;
    })
    .set(await getAuthHeader())
    .then(({ body }) => body)
    .catch(logApiFailureEvent);
};

export { fetchPayrollData, fetchLifeTimeEarnings };
