import { IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import { FC } from "react";

import { SummaryProps } from "./models";
import { formatDollarsAsUsd } from "../../utils/currency";
const Summary: FC<SummaryProps> = ({ earnings, shifts }) => {
  return (
    <div className="ion-text-center">
      <IonGrid style={{ borderBottom: "1px dashed" }}>
        <IonRow>
          <IonCol>
            <h6>Lifetime Earnings</h6>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel style={{ fontSize: "-webkit-xxx-large" }}>
              {formatDollarsAsUsd(earnings)}
            </IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <h4>{shifts || "No "} Shifts</h4>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export { Summary };
