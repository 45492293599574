import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import moment, { Moment } from "moment-timezone";
import { FC } from "react";

import { ShiftListItemProps } from "./models";

const formatDate = (date: Moment | string) => moment(date).format("Do MMM, YYYY");
const formatTime = (date: Moment | string) => moment(date).format("HH:mm a, (z)");

const ShiftListItem: FC<ShiftListItemProps> = ({ shift }) => (
  <IonItem key={shift._id}>
    <IonGrid>
      <IonRow>
        <IonCol size="7">{formatDate(shift.start)}</IonCol>
        <IonCol size="5" style={{ textAlign: "end" }}>
          {formatTime(shift.start)}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="9">{`${shift.facility.name}`}</IonCol>
        <IonCol size="3" style={{ textAlign: "end" }}>
          {shift.time} Hours
        </IonCol>
      </IonRow>
    </IonGrid>
  </IonItem>
);

export { ShiftListItem };
