import { PaymentServiceAccountStatus } from "@src/appV2/Worker/api/types";
import { Attributes } from "react";

export interface PayrollSettingsDropdownProps {
  balance: Balance;
  currentPaySchedule: ClipboardPaySchedule;
  onPayScheduleChange: (s: ClipboardPaySchedule) => void;
}

export interface Balance {
  availableNow: number;
  availableNowMinusFee: number;
  availableSoon: number;
  hasDebitCard: boolean;
  withdrawalFee: number;
  autoWithdrawEnabled: boolean;
}

export interface BubbleProps {
  icone: string;
  description: string;
  loading: boolean;
  value: Attributes;
  dropdown?: Attributes;
  valueFontSize?: number;
  storeHideDropDown?: (callback: () => void) => () => void;
}

export interface PayrollItemProps {
  icone: string;
  name: string;
  onClick: () => void;
}

export interface ChevronProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  description: string; // for test-id
  ariaLabel: string;
}

export const InvalidPaymentAccountStatus = [
  PaymentServiceAccountStatus.INSTANT_PAYOUT_PENDING,
  PaymentServiceAccountStatus.STANDARD_PAYOUT_PENDING,
];

export type ClipboardPaySchedule = "daily" | "weekly" | "manual" | "instantly";

export interface ShiftDetailForBonus {
  _id: string;
  name: string;
  facility: {
    tmz: string;
    name: string;
  };
  start: string;
  end: string;
}
