import { IonItem, IonLabel, IonList, IonSkeletonText } from "@ionic/react";
import { FC } from "react";

const ShiftListLoader: FC = () => {
  return (
    <IonList data-testid="shift-list-loader">
      <IonItem>
        <IonLabel>
          <IonSkeletonText animated style={{ width: "100%" }} />
          <IonSkeletonText animated style={{ width: "100%" }} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <IonSkeletonText animated style={{ width: "100%" }} />
          <IonSkeletonText animated style={{ width: "100%" }} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>
          <IonSkeletonText animated style={{ width: "100%" }} />
          <IonSkeletonText animated style={{ width: "100%" }} />
        </IonLabel>
      </IonItem>
    </IonList>
  );
};

export { ShiftListLoader };
