import { BonusList } from "@src/lib/interface/src";
import moment from "moment-timezone";

import { BONUS_TYPE, PAYMENT_STATUS } from "./constants";
import { ShiftDetailForBonus } from "./models";

interface PaymentHistoryBonusData {
  _id: string;
  shiftId?: string;
  group?: string[];
  amount: number;
  status: string;
  reason: string;
  type?: string;
  payPeriodStart: string;
  payPeriodEnd: string;
  createdAt: string;
  facility?: {
    tmz: string;
    name?: string;
  };
}

interface FormatBonusesProps {
  bonuses: PaymentHistoryBonusData[];
  shiftDetails: ShiftDetailForBonus[];
  agentTimezone: string;
}

export function formatBonuses(props: FormatBonusesProps) {
  const { bonuses, shiftDetails, agentTimezone } = props;

  const shiftsById = shiftDetails.reduce<Record<string, ShiftDetailForBonus>>((acc, v) => {
    return {
      ...acc,
      [v._id]: v,
    };
  }, {});

  const groupedBonuses = bonuses.reduce<Record<string, PaymentHistoryBonusData[]>>((acc, bonus) => {
    const groupKey = (() => {
      if (bonus.type === BONUS_TYPE.TBIO_BACK_TO_BACK) {
        return bonus.group!.join("-");
      }
      if (bonus.type === BONUS_TYPE.TBIO_SINGLE) {
        return bonus.shiftId!;
      }
      return bonus._id;
    })();

    const existing = acc[groupKey] ?? [];
    return {
      ...acc,
      [groupKey]: [...existing, bonus],
    };
  }, {});

  return Object.entries(groupedBonuses).map<BonusList>(([id, group]) => {
    const [bonus] = group;

    let status = PAYMENT_STATUS.PENDING;
    if (group.every((b) => b.status === PAYMENT_STATUS.PAID)) {
      status = PAYMENT_STATUS.PAID;
    } else if (group.some((b) => b.status === PAYMENT_STATUS.PAID)) {
      status = PAYMENT_STATUS.PARTIAL;
    }

    let { reason, amount } = bonus;
    if (bonus.reason === "Extra Time Pay") {
      reason = "";
      const defaultBonusData = {
        name: "",
        start: bonus.payPeriodStart,
        facility: {
          tmz: agentTimezone,
          name: "",
        },
      };
      if (bonus.type === BONUS_TYPE.TBIO_SINGLE) {
        const bonusShift = shiftsById[bonus.shiftId!] || defaultBonusData;
        const payStartFormatted = moment(bonus.payPeriodStart)
          .tz(bonusShift.facility.tmz)
          .format("MM/DD");
        reason += `${payStartFormatted} ${bonusShift.name.toUpperCase()} Workday bonus`;
        amount = group.reduce((acc, v) => acc + v.amount, 0);
      } else if (bonus.type === BONUS_TYPE.TBIO_BACK_TO_BACK) {
        const info = bonus.group!.reduce<Record<string, string[]>>((res, cur) => {
          const bonusShift = shiftsById[cur] || defaultBonusData;
          const period = moment(bonusShift.start).tz(bonusShift.facility.tmz).format("MM/DD");
          res[period] = res[period] || [];
          res[period].push(bonusShift.name);
          return res;
        }, {});
        const shiftNamesByPeriod = Object.entries(info).map(
          ([period, shiftNames]) => `${period} ${shiftNames.join(" & ")}`
        );
        reason += `${shiftNamesByPeriod.join(" & ").toUpperCase()} Workday bonus`;
        amount = group.reduce((acc, v) => acc + v.amount, 0);
      } else {
        reason += `${moment(bonus.payPeriodStart).format("MM/DD/YY")} - ${moment(
          bonus.payPeriodEnd
        ).format("MM/DD/YY")} Weekly bonus`;
      }
    }

    return {
      _id: id,
      amount,
      reason,
      status,
      createdAt: group[group.length - 1].createdAt,
      shiftId: group[group.length - 1].shiftId!,
      type: "BONUS",
      originalReason: bonus.reason,
      payPeriodStart: bonus.payPeriodStart,
      payPeriodEnd: bonus.payPeriodEnd,
      facility: shiftsById[bonus.shiftId ?? bonus.group?.[0] ?? ""]?.facility,
    };
  });
}
